import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/index/navbar"
import Layout from "../components/layout"
import Seo from "../components/SEO/seo"
import { Sidebar } from "../components/ui/sidebar"
import { collapseEvent } from "../helpers/collapseFuntion"
import { ListPriceButton } from "../helpers/ListPriceButton"
import "../styles/main.scss"

const Coagulacion = () => {
  const { handleEventCollapse } = collapseEvent()

  //PAGE IMAGE
  const imageUrl = useStaticQuery(
    graphql`
      query {
        siteImg: file(
          relativePath: { eq: "Services/coagulacion_334460245.jpeg" }
        ) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  )

  const { src: imgSource } = imageUrl.siteImg.childImageSharp.fluid //image

  const parametersSEO = {
    description:
      "Es el proceso por el que se forma un coágulo de sangre. Los trastornos de la coagulación pueden provocar una cantidad peligrosa de hemorragias o coagulaciones.", //CHANGE
    lang: "es-SV",
    meta: [],
    title: "Coagulación Laboratorio-Clinico",
    imgSrc: imgSource,
    currentURL: "https://laboratorioclinico.sv/coagulacion",
    canonicalURL: "https://laboratorioclinico.sv",
    ogTitle: "Coagulación laboratorioclinico",
    keywords: [
      "fibrinogeno",
      "tiempo de sangramiento",
      "tiempo de coagulación",
      "coagulacion",
      "coagulación",
      "hemogoblina",
    ],
  }

  return (
    <>
      <Layout>
        <Seo {...parametersSEO} />
        <div className="banner-services__container">
          <svg
            width="1418"
            height="509"
            viewBox="0 0 1418 509"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1170.03 297.846L1121.05 337.513L1029.23 198.661C1024.68 191.802 1014.82 187.555 1003.41 187.555C994.136 187.555 982.379 190.604 977.179 199.205L897.062 332.178L786.458 14.2989C782.795 3.73804 769.442 0 759.162 0C747.168 0 733.874 4.7542 731.215 15.3877L634.969 399.39L537.008 146.509C533.05 136.275 519.815 132.646 509.653 132.646C499.786 132.646 487.556 135.985 482.888 145.312L397.04 317.806L333.821 225.988C329.212 219.274 319.345 215.137 308.061 215.137C298.962 215.137 290.69 217.895 285.313 222.722L211.046 288.229L205.905 290.333H29.5417C13.2347 290.333 0 298.463 0 308.479C0 318.496 13.2347 326.625 29.5417 326.625H223.571C232.552 326.625 241.06 323.939 246.377 319.149L302.388 270.046L375.652 376.345C380.319 383.131 389.891 387.196 401.235 387.196C413.701 387.196 424.277 382.188 428.118 374.494L506.344 217.278L613.521 494.147C617.539 504.418 630.183 508.083 640.7 508.083C652.694 508.083 665.987 503.329 668.646 492.66L763.475 114.319L862.321 398.483C865.748 408.354 878.687 412.818 889.913 412.818C901.848 412.818 911.538 408.463 915.969 401.168L1004.59 254.223L1088.85 381.607C1093.4 388.466 1103.32 392.712 1114.73 392.712C1123.71 392.712 1131.98 389.99 1137.3 385.236L1205 327.786L1210.14 326.117H1388.93C1405 326.117 1418 318.133 1418 308.262C1418 298.39 1405 290.333 1388.93 290.333H1192.66C1183.73 290.333 1175.29 293.128 1170.03 297.846V297.846Z"
              fill="white"
            />
          </svg>

          <div className="banner-services__container-fade-in"></div>
          <div className="banner-services__container-banner">
            <NavBar />
            <header className="banner-service">
              <div className="banner-service__button">
                <i className="fas fa-circle fa-xs"></i>
                <p>Servicios de Alta Calidad</p>
              </div>
              <h1 className="banner-service__title mt-2 mb-3">Coagulación</h1>
            </header>
          </div>
        </div>
        <div className="content-services__container">
          <StaticImage
            src="../images/content.png"
            className="banner-content__background"
            placeholder="tracedSVG"
            width={1600}
            alt="background"
          ></StaticImage>

          {/* MAIN CONTENT WITH SIDEBAR */}
          <section className="content-services">
            <Sidebar />
            <div className="content-services__container">
              <div className="content-services__container-images">
                <StaticImage
                  src="../images/Services/coagulacion_334460245.jpeg"
                  alt="coagulacion_img"
                  placeholder="none"
                  layout="constrained"
                />
              </div>
              <div className="content-services__container-section1 mt-2">
                <h2 className="mb-1">¿Qué es la Coagulación?</h2>
                <p>
                  Es el proceso por el que se forma un coágulo de sangre. La
                  formación de un coágulo suele denominarse hemostasia
                  secundaria, porque constituye la segunda etapa en el proceso
                  de detención de la pérdida de sangre de un vaso roto. La
                  primera etapa, la hemostasia primaria, se caracteriza por la
                  constricción de los vasos sanguíneos (vasoconstricción) y la
                  agregación de plaquetas en el lugar de la lesión del vaso. En
                  circunstancias anormales, también pueden formarse coágulos en
                  un vaso que no se ha roto; estos coágulos pueden provocar la
                  oclusión (bloqueo) del vaso.
                </p>
              </div>
              <div className="content-services__container-section2 mt-2">
                <ul>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Tiempo de coagulación
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Tiempo de sangramiento
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Tiempo de Protombina TP
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Tiempo de Tromboplastina Parcial TTP
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Fibrinogeno
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Tiempo de Trombina TT
                  </li>
                </ul>
              </div>
              <div className="content-services__container-section3">
                <h2 className="mb-1 mt-2">
                  ¿Para qué se hace un examen de coagulación de sangre?
                </h2>
                <p>
                  Los trastornos de la coagulación pueden provocar una cantidad
                  peligrosa de hemorragias o coagulaciones. Si su médico
                  sospecha que tiene un trastorno de la coagulación, puede
                  recomendarle una o varias pruebas de coagulación. Estas
                  pruebas miden varias proteínas y su funcionamiento.
                </p>
              </div>
              <ul className="content-services__container-section4 mt-2 mb-3">
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿El examen de Coagulación es en ayunas?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Sí, El ayuno antes de determinados análisis de sangre es
                    importante para garantizar la exactitud de los resultados.
                    Las vitaminas, los minerales, las grasas, los hidratos de
                    carbono y las proteínas que componen todos los alimentos y
                    las bebidas pueden influir en las lecturas de los niveles de
                    sangre, enturbiando los resultados del análisis.
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Para qué es el examen de protombina?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Para detectar y diagnosticar un trastorno hemorrágico o de
                    coagulación excesivo. Básicamente mide el tiempo en el que
                    la sangre se coagula y así poder recetar el medicamento
                    necesario.
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <ListPriceButton />
        <Footer />
      </Layout>
    </>
  )
}

export default Coagulacion
